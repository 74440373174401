import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { Article } from 'superclient';
import EditorialContributorIcon from './editorial-contributor-icon';

const EditorialAppHeader: FC<{ article?: Article }> = ({ article }) => {
  if (!article) return null;

  return (
    <div className="article__app__header">
      <h1>{article.title}</h1>
      <div className="x f fr article__app__small-text mt1">
        {(article.tags || []).join(', ')}
      </div>
      <div className="x f fr mt15">
        <div className="f1 f fc aic">
          {article.credits.map((c) => (
            <div key={c.id} className={'x f fr aic py05'}>
              <div style={{ width: '30px' }}>
                <EditorialContributorIcon type={c.type} />
              </div>
              <span className="article__app__small-text rel" style={{ top: '2px' }}>
                {c.name}
              </span>
            </div>
          ))}
        </div>
        <div className="bcb cw p05 asfs">
          <span className="article__app__small-text">
            {DateTime.fromISO(article.date).toFormat('DDD')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditorialAppHeader;
