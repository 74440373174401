import React, { FC, useEffect, useRef } from 'react';
import Mark from 'mark.js';
import { Article, GlossaryTerm } from 'superclient';
import EditorialModules from '../components/editorial/editorial-modules';
import EditorialAppHeader from '../components/editorial/editorial-app-header';
import EditorialFeaturedContent from '../components/editorial/editorial-featured-content';
import EditorialSponser from '../components/editorial/editorial-sponser';

const TemplateArticleApp: FC<{
  pageContext: { article: Article; glossaryTerms: GlossaryTerm[] };
}> = ({ pageContext: { article, glossaryTerms } }) => {
  const { content, featuredContent, sponsored, sponsoredTextOverride } = article;

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (bodyRef.current) {
        if (!bodyRef.current || !glossaryTerms) return;

        const instance = new Mark(bodyRef.current);
        glossaryTerms.forEach((term) => {
          instance.mark(term?.title, {
            accuracy: {
              value: 'exactly',
              limiters: ['!', '.', ',', '’', '-', `'`]
            },
            separateWordSearch: false,
            element: 'a',
            className: 'article__app__highlight_a highlight',
            each: (node) => {
              (node as HTMLAnchorElement).href = 'https://glossary/' + term._id;
              node.addEventListener('onclick', () => {
                window.postMessage(term.title, window.location.hostname);
              });
            }
          });
        });
      }

      const sharable = require('sharable/browser');
      // eslint-disable-next-line
      const share = sharable.default();
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://platform.twitter.com/widgets.js';
      document.body.appendChild(s);
    }, 500);
  }, [glossaryTerms]);

  return (
    <div className="article__app">
      <EditorialAppHeader article={article} />
      <EditorialFeaturedContent featuredContent={featuredContent} />
      <div className="article__content" ref={bodyRef}>
        <EditorialModules modules={content.content} isMobile />
      </div>
      <EditorialSponser
        sponsered={sponsored}
        sponsoredTextOverride={sponsoredTextOverride}
      />
    </div>
  );
};

export default TemplateArticleApp;
